import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import { LuAlertCircle } from "react-icons/lu";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AIIndicator from "./AIIndicator";

function Main() {
  const [text, setText] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [aiPercentage, setAIPercentage] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanned, setIsScanned] = useState(false);

  const handleAnalyze = async () => {
    if (wordCount < 101) {
      setShowAlert(true);
      return;
    }
    setShowAlert(false);
    try {
      setIsLoading(true);
      const response = await axios.post("https://faktual.ai/api/detect/detect", {
        text: text,
        api_key:
          "yu76xv4cfa96f20af2ca8cfdf9c5920f54d78f1b835318d729ceec2a72403cc29",
      });
      highlightText(response.data.final_result);
      setAIPercentage(response.data.ai_final_percentage);
      setIsScanned(true);
    } catch (error) {
      toast.error(error.response?.data?.detail);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();
    let pastedText = (e.clipboardData || window.clipboardData).getData("text");
    pastedText = pastedText.replace(/\s+/g, " ").trim(); // Remove extra spaces and newlines
    document.execCommand("insertText", false, pastedText);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    const newWordCount = newText
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
    if (newWordCount > 10000) {
      toast.error(
        "Word limit exceeded. Please enter a maximum of 10,000 words."
      );
      return;
    }

    setText(newText);
    setWordCount(newWordCount);
    if (newWordCount >= 101) {
      setShowAlert(false);
    }
  };

  const getHighlightColor = (source, percentage) => {
    const confidence = parseFloat(percentage) / 100;
    if (source === "Machine") {
      if (confidence > 0.8) return "#DC3545"; // Red for high AI confidence
      if (confidence > 0.6) return "#ff9999"; // Yellow for medium AI confidence
      if (confidence > 0.4) return "#fefebe"; // Light red for low AI confidence, likely AI-generated
    } else if (source === "Human") {
      if (confidence > 0.8) return "#198754"; // Green for high human confidence
      if (confidence > 0.6) return "#b3ffb3"; // Light green for medium human confidence
    }
    return "#d6d6d6"; // Default color for low confidence
  };

  // const highlightText = (analysis) => {
  //   let highlighted = text;
  //   analysis.forEach((line) => {
  //     const color = getHighlightColor(line.source, line.percentage);
  //     const highlightedLine = `<span style="background-color: ${color}">${line.text}</span>`;
  //     highlighted = highlighted.split(line.text).join(highlightedLine);
  //   });
  //   setHighlightedText(highlighted);
  // };

  const highlightText = (analysis) => {
    let highlighted = [];
    analysis.forEach((line) => {
      const color = getHighlightColor(line.source, line.percentage);
      const highlightedLine = `<span style="background-color: ${color} ; color: #fff;">${line.text}</span>`;
      highlighted.push(highlightedLine);
    });
    setHighlightedText(highlighted.join(" "));
  };

  const handleNewScan = () => {
    setText("");
    setHighlightedText("");
    setWordCount(0);
    setIsScanned(false);
    setShowAlert(false);
    setAIPercentage(0);
  };

  return (
    <div className="m-4">
      <Row>
        <Col xs={12} md={9}>
          <h3 className="text-center">AI Detector</h3>

          <Form.Group controlId="textInput">
            <Form.Label className="text-muted" style={{ fontSize: "12px" }}>
              Paste your content below, and we’ll tell you if any of it has been
              AI-generated within seconds with exceptional accuracy.
            </Form.Label>
            {!isScanned ? (
              <Form.Control
                as="textarea"
                rows={15}
                value={text}
                onChange={handleTextChange}
                onPaste={handlePaste}
                placeholder="Enter text here"
                style={{ background: "#f8f9fa", height: "400px" }}
              />
            ) : (
              <div
                className="p-3"
                style={{
                  height: "400px",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  background: "#f8f9fa",
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: highlightedText }}
              ></div>
            )}
            <Form.Text className="text-muted" style={{ fontSize: "12px" }}>
              {wordCount}/10000 Words
            </Form.Text>
            {showAlert && (
              <div
                className="mt-2 d-flex align-item-center"
                style={{
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LuAlertCircle />
                &nbsp; Insufficient data for analysis. Please enter at least 101
                words.
              </div>
            )}
          </Form.Group>
          <div className="d-flex justify-content-center mt-3">
            {!isScanned ? (
              <Button
                className="px-5"
                variant="dark"
                onClick={handleAnalyze}
                disabled={isLoading || wordCount <= 0}
              >
                {isLoading ? "Analyzing..." : "Scan"}
              </Button>
            ) : (
              <Button className="px-5" variant="dark" onClick={handleNewScan}>
                New Scan
              </Button>
            )}
          </div>
        </Col>
        <Col xs={12} md={3} className="mt-4 mt-md-0">
          <AIIndicator aiPercentage={aiPercentage} isScanned={isScanned} />
        </Col>
      </Row>

      <h3 className="mb-3 mt-4">Highlight Color Key</h3>
      <div className="d-flex align-items-center mb-2">
        <div
          className="rounded-circle"
          style={{
            backgroundColor: "#DC3545",
            height: "15px",
            width: "15px",
          }}
        />
        <p className="ms-2 my-auto">
          90% confidence this sentence was generated by AI.
        </p>
      </div>
      <div className="d-flex align-items-center mb-2">
        <div
          className="rounded-circle"
          style={{
            backgroundColor: "#ff9999",
            height: "15px",
            width: "15px",
          }}
        />
        <p className="ms-2 my-auto">
          70% confidence this sentence was generated by AI.
        </p>
      </div>
      <div className="d-flex align-items-center mb-2">
        <div
          className="rounded-circle"
          style={{
            backgroundColor: "#fefebe",
            height: "15px",
            width: "15px",
          }}
        />
        <p className="ms-2 my-auto">
          50% confidence this sentence was generated by AI.
        </p>
      </div>
      <div className="d-flex align-items-center mb-2">
        <div
          className="rounded-circle"
          style={{
            backgroundColor: "#b3ffb3",
            height: "15px",
            width: "15px",
          }}
        />
        <p className="ms-2 my-auto">
          70% confidence this sentence was human-written.
        </p>
      </div>
      <div className="d-flex align-items-center mb-2">
        <div
          className="rounded-circle"
          style={{
            backgroundColor: "#198754",
            height: "15px",
            width: "15px",
          }}
        />
        <p className="ms-2 my-auto">
          90% confidence this sentence was human-written.
        </p>
      </div>
    </div>
  );
}

export default Main;
