import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const AIIndicator = ({ aiPercentage, isScanned }) => {
  const isLikelyOriginal = aiPercentage < 50;

  const displayValue = isLikelyOriginal ? 100 - aiPercentage : aiPercentage;
  const displayText = isLikelyOriginal ? "Likely Original" : "Likely AI";
  const pathColor = isLikelyOriginal ? "#198754" : "#DC3545";
  const textColor = isLikelyOriginal ? "#198754" : "#DC3545";
  const badgeColor = isLikelyOriginal ? "bg-success" : "bg-danger";

  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const date = new Date();
  const formattedDate = date.toLocaleString("en-US", options).replace(",", "");

  return (
    <>
      <h3 className="mb-3 text-center">AI Results</h3>

      {isScanned && (
        <p className="mb-3 fw-medium text-center">
          We are highly confident that this text is{" "}
          <span className={`badge text-${badgeColor}`}>
            {displayText === "Likely AI" ? "AI Generated" : "Human Generated"}
          </span>
        </p>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgressbar
          className="custom-progress-bar"
          value={isScanned === true ? displayValue : 0}
          text={isScanned === true ? displayText : ""}
          styles={buildStyles({
            strokeLinecap: "butt",
            textSize: "12px",
            pathTransitionDuration: 1,
            pathColor: pathColor,
            textColor: textColor,
          })}
        />
      </div>

      <div className="d-flex justify-content-around my-3">
        <div className="content-section">
          <h5 className="mb-0 mt-2 text-center">
            {isScanned === true
              ? `${displayValue.toFixed(1)}% Confidence`
              : `0% Confidence`}
          </h5>
          <p
            className="mb-3 text-center text-muted fw-medium"
            style={{ fontSize: "14px" }}
          >
            ({formattedDate})
          </p>
        </div>
        <div className="vertical-line"></div>
        <div className="legend-section">
          <h6 className="mb-1 text-center" style={{ color: "#DC3545" }}>
            Dark Red = Likely AI
          </h6>
          <h6 className="mb-1 text-center" style={{ color: "#198754" }}>
            Dark Green = Likely Original
          </h6>
        </div>
      </div>
      <p className="text-center fw-medium lh-base">
        This score represents our AI's confidence in determining whether the
        scanned content was produced by an AI tools.
      </p>
    </>
  );
};

export default AIIndicator;
